import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import BlockCallToAction from '../../components/ACF/BlockCallToAction/BlockCallToAction'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Icon from '../../components/Icon/Icon'

const BrandAndModelPage = ({ data }) => {
	const brandName = data.product.brand.name
	const modelName = data.product.model.name

	const popAndBangData = {
		seo: {
			canonical: `/chiptunen/${brandName}-${modelName}`,
			cornerstone: false,
			focuskw: `Chiptunen ${brandName} ${modelName}`,
			metaDesc: `Wilt u uw ${brandName} ${modelName} laten chiptunen? `,
			metaKeywords: `chiptunen ${brandName} ${modelName} - ECU adaptions`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `chiptunen ${brandName} ${modelName} - ECU adaptions`,
			title: `Chiptunen ${brandName} ${modelName} - ECU adaptions`
		},
		header: {
			backgroundColor: '#000000',
			backgroundOpacity: 0.2,
			fieldGroupName: 'header',
			height: 50,
			heroChoice: 'image',
			heroText: `Chiptunen ${brandName} ${modelName}`,
			isFrontPage: false,
			image: data?.brand?.image,
			pxOrVh: 'vh',
			videoUrl: null
		},
		...data?.brand
	}

	const ctaData = { callToAction: data.wpCtaPost }

	return (
		<PageLayout pageData={popAndBangData}>
			<BrandPageContentWrapper padding={'80px 0 0 0'}>
				<Title>
					Chiptunen {brandName} {modelName}
				</Title>
				<ContentContainer>
					<TextContainer>
						<TextTitle>
							Een boost voor uw {brandName} {modelName}
						</TextTitle>
						<Text>
							{`Wanneer u overweegt om de prestaties van uw ${brandName} ${modelName} te verbeteren, kunt u kiezen voor
							het chiptunen van uw ${brandName} ${modelName}, chiptunen is een technologische upgrade die uw voertuig voorziet van een aanzienlijk hogere acceleratie,
							meer pit en een verbeterde souplesse. Voor de ultieme chiptuningervaring is ECU adaptions Chiptuning de onbetwiste
							autoriteit als het gaat om het optimaliseren van het motormanagement van uw ${brandName} ${modelName}. Wat
							ECU adaptions Chiptuning echt uniek maakt in deze branche, is hun vermogen om aangepaste software te ontwikkelen die
							specifiek is afgestemd op ${brandName}-voertuigen, waaronder uw geliefde ${brandName} ${modelName}. Dit betekent dat elke
							chiptuning die door ECU Adaptions wordt uitgevoerd, is gebaseerd op hun diepgaande kennis van ${brandName} ${modelName}-motoren en hun
							capaciteit om software te creëren die perfect aansluit op de behoeften van uw specifieke voertuig. Het
							resultaat van deze toewijding aan precisie en deskundigheid is een chiptuningproces dat synoniem staat
							voor duurzaamheid, betrouwbaarheid en bovenal veiligheid. 
                            `}
						</Text>
						<Text>
							{`U kunt erop vertrouwen dat wanneer u uw ${brandName} ${modelName} door ECU Adaptions laat chiptunen, u niet alleen geniet van verbeterde prestaties, maar ook van
							gemoedsrust, wetende dat uw voertuig in veilige handen is. Dus, als u op zoek bent naar de ultieme
							chiptuningervaring voor uw ${brandName} ${modelName}, zoek dan niet verder dan ECU Adaptions Chiptuning. Ze zullen uw
							rijervaring naar nieuwe hoogten tillen, terwijl ze tegelijkertijd de integriteit en de betrouwbaarheid van
							uw geliefde ${brandName} ${modelName} waarborgen.`}
						</Text>
					</TextContainer>
					<FeaturesContainer>
						<TextTitle>
							Dit zijn de voordelen van het op maat chiptunen van jouw {brandName} {modelName} door ECU Adaptions:
						</TextTitle>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer vermogen
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer trekkracht
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Minder brandstofverbruik
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Meer rijplezier en comfort
						</FeatureItem>
					</FeaturesContainer>
				</ContentContainer>
				<BlockCallToAction data={ctaData} />
			</BrandPageContentWrapper>
		</PageLayout>
	)
}

const BrandPageContentWrapper = styled.section`
	padding: ${props => props.padding};
`

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
	}
`

const TextContainer = styled.div`
	flex: 3;
	margin-right: 3rem;
	margin-bottom: 3rem;
`

const FeaturesContainer = styled.div`
	flex: 2;
	margin-bottom: 3rem;
`

const Title = styled.h1`
	margin: 0;
	margin-bottom: 3rem;
`

const TextTitle = styled.h5`
	margin: 0;
	margin-bottom: 1rem;
	max-width: 600px;
`

const Text = styled.p`
	margin: 0;
	margin-bottom: 1.5rem;
`

const Notice = styled.p`
	margin: 0;
	font-style: italic;
	color: rgb(129, 129, 129);
`

const FeatureItem = styled.p`
	display: flex;
	flex-direction: row;
	margin: 0;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	align-items: center;
`

export default BrandAndModelPage

export const query = graphql`
	query BrandAndModelQuery($productId: String!, $brandId: String!) {
		brand(_id: { eq: $brandId }) {
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1920)
					}
				}
			}
		}
		product(id: { eq: $productId }) {
			id
			brand {
				name
				_id
			}
			model {
				name
				_id
			}
		}

		wpCtaPost(slug: { eq: "offerte-aanvragen" }) {
			id
			title
			postContent {
				buttonText
				imageOverlay
				imageOverlayOpacity
				paddingBottom
				paddingTop
				text
				buttonLink {
					url
				}
				image {
					altText
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1920)
						}
					}
				}
			}
		}
	}
`
